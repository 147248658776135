import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";

const ServiceCard = ({color, iconContainerColor, title, description, icon, imgUrl }) =>{
    const Icon = icon
    return (
        <Container >
            <IconContainer bgColor={iconContainerColor} >
                {/* <Icon size={35} color={color} /> */}
                <ImageComp src={imgUrl} />
                {/* <ImageComp src={{uri: imgUrl}} /> */}
            </IconContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>

        </Container>
    )
}

export default ServiceCard;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 27%;
    transform: translate(0px, 0px);
    transition: transform 1s ease-in-out;
    &:hover {
        transform: translate(0px, -7px);
        transition: transform 1s ease-in-out;
    }
    
`

const IconContainer = styled.div`
    display: flex;
    width: 100px;
    height: 100px;
    background-color: ${({bgColor})=> bgColor};
    align-items: center;
    justify-content: center;
    border-radius: 50px;

`

const Title = styled.a`
    font-size: 25px;
    text-decoration: none;
    font-family: GILROY-bold;
    margin: 5px 0 0 0;
    &:hover {
        color: ${colors.primaryLight};
    }
`

const Description = styled.p`
    /* color: ${colors.grey}; */
    padding: 5px 0px 0px 0px ;
    font-family: GILROY-MEDIUM;
`

const ImageComp = styled.img`
    width: 40px;
    height: 40px;
`