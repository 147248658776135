import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import brnad1 from "../assets/clients/atidot.png";
import brnad2 from "../assets/clients/sl.png";
import brnad3 from "../assets/clients/SG.png";
import brnad4 from "../assets/clients/wonder.png";
import brnad5 from "../assets/clients/vetigenics.png";
import brnad6 from "../assets/clients/way.png";
import brnad7 from "../assets/clients/spa.png";
import brnad8 from "../assets/clients/rite.png";
import ClientCard from "../components/clients/ClientCard";

const Clients = () =>{
    const { width, height } = useWindowDimensions();
    return (
        <div id="#element" style={{position: "relative", zIndex: 1}}>
            <Container width={width} height={height}>
                <HeadContainer>
                    <TitleContainer>
                        <GradientText>Our Awesome Client</GradientText>
                    </TitleContainer>
                    {/* <SubHeading width={width}>Our Awesome Client.</SubHeading> */}
                    <MainHeading width={width}>OUR PARTNERS IN</MainHeading>
                <div ></div>
                <ShadowHeading width={width}>CREATIVITY</ShadowHeading>
                </HeadContainer>
                <InnerContainer style={{marginTop: 50}}>
                    <ClientCard image={brnad1} width={width} />
                    <ClientCard image={brnad2} width={width} />
                    <ClientCard image={brnad3} width={width} />
                    <ClientCard image={brnad4} width={width} />
                </InnerContainer>
                <InnerContainer>
                    <ClientCard image={brnad5} width={width} />
                    <ClientCard image={brnad6} width={width} />
                    <ClientCard image={brnad7} width={width} />
                    <ClientCard image={brnad8} width={width} />
                </InnerContainer>

            </Container>
        </div>
    )
}

export default Clients;

const Container = styled.div`
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    margin-bottom: 100px;

`

const HeadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GradientText = styled.p`
    font-size: 20px;
    background: -webkit-linear-gradient(45deg, ${colors.primary}, ${colors.primaryLight});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-weight: bold;
    font-family: GILROY-MEDIUM;

`

const TitleContainer = styled.div`
    background: ${colors.greyDark};
    padding: 10px;
    margin: 0px;
    border-radius: 5px;
`

const SubHeading = styled.p`
    font-size: ${({width})=> width * 0.03}px;
    font-weight: bold;
    margin-top: 20px;
`

const InnerContainer = styled.div`
    display: flex;
`

const MainHeading = styled.h1`
    width: ${({width})=> width}px;
    font-size: ${({width})=> width / 22}px;
    letter-spacing: 4px;
    margin: 0px;
    z-index: 0;
    /* padding-bottom: 0px; */
    font-family: GILROY-Medium;
`


const ShadowHeading = styled.h1`
    width: ${({width})=> width }px;
    font-size: ${({width})=> width / 18}px;
    margin: 0px;
    letter-spacing: 4px;
    font-family: GILROY-heavy;
    background: -webkit-linear-gradient( -90deg, #030000, ${colors.primary});
    color: transparent;
    -webkit-background-clip: text;
    line-height: ${({width})=> width * 0.06  }px;

`