import React from "react";
import styled, { css } from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";

function InputText({title, placeholder, textArea, value, inputType, onChange, inputStyle, inputContainerStyle}) {
    const { width, height } = useWindowDimensions();
  return (
        textArea ?
        <Container width={width} height={height} style={inputContainerStyle} >
            {/* <Title>{title}<sup>*</sup></Title> */}
            <TextArea type={inputType} style={inputStyle} value={value} onChange={onChange} placeholder={placeholder}  />
        </Container>
        :
        <Container width={width} height={height} style={inputContainerStyle}>
            {/* <Title>{title}<sup>*</sup></Title> */}
            <Input type={inputType} style={inputStyle} value={value} onChange={onChange} placeholder={placeholder}  />
        </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: ${({height})=> height * 0.05}px ; */
    margin-top: 20px;
    width: 70%;
`

// const Title = styled.p`
//     color: ${colors.white};
//     font-size: 12px;
//     text-align: left;
// `

const inputStyle = css`
    border: 0px;
    background-color: ${colors.greyDark};
    color: ${colors.white};
    border-radius: 5px;
    padding: 10px;
    height: 30px;
`

const Input = styled.input`
    ${inputStyle}
`

const TextArea = styled.textarea`
    ${inputStyle}
`

export default InputText;
