import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import { FaStar } from "react-icons/fa";
import { HiCheck } from "react-icons/hi";

const Pricings = () =>{
    const { width, height } = useWindowDimensions();
    const array5 = [1,2,3,4,5];
    return (
        <div id="#element" style={{position: "relative", zIndex: 1}}>
            <Container width={width} height={height}>
                <HeadContainer>
                    <TitleContainer>
                        <GradientText>Our Pricing Plan</GradientText>
                    </TitleContainer>
                    <SubHeading width={width}>Web Pricing Plan.</SubHeading>
                </HeadContainer>
                <PromotionContainer>
                    <PromotionBlock style={{backgroundColor: colors.greyLighter}}>
                        <SubHeading width={width}>Active Plan Mode.</SubHeading>
                        <Description>A good traveler has no fixed plansA good traveler has no fixed plans</Description>
                        <PriceTagContainer>
                            <SubHeading width={width} style={{margin: 0, marginRight: 10}}>$129</SubHeading>
                            <sup style={{fontWeight: "bold"}}>/mo</sup>
                        </PriceTagContainer>
                        <RowContainer>
                            <GradientPlanButton>Purchase Now</GradientPlanButton>
                            <PlanButton >Upgrade</PlanButton>
                            <div style={{marginTop: 20}}>{array5.map(star=> <Stars />)}</div>
                            <Description>rated 4.5/5 Stars in 1000+ reviews.</Description>
                        </RowContainer>
                    </PromotionBlock>
                    <PromotionBlock style={{backgroundColor: colors.greyDark}}>

                        <PromotionTitle style={{fontSize: 25, marginTop: 40}}>Advance Plans You can Get.</PromotionTitle>
                        <Description><HiCheck/> 5 PPC Campaigns</Description>
                        <Description><HiCheck/> Digital Marketing</Description>
                        <Description><HiCheck/> Marketing Agency</Description>
                        <Description><HiCheck/> Seo Friendly</Description>

                        <PromotionTitle style={{fontSize: 25}}>Advance Plans You can Get.</PromotionTitle>
                        <Description><HiCheck/> 5 PPC Campaigns</Description>
                        <Description><HiCheck/> Digital Marketing</Description>
                        <Description><HiCheck/> Marketing Agency</Description>
                        <Description><HiCheck/> Seo Friendly</Description>
                        <Description><HiCheck/> App Development</Description>
                        <Description><HiCheck/> Social Development</Description>
                        
                    </PromotionBlock>
                </PromotionContainer>
            </Container>
        </div>
    )
}

export default Pricings;

const Container = styled.div`
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    margin-bottom: 100px;
`

const HeadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GradientText = styled.p`
    font-size: 20px;
    background: -webkit-linear-gradient(45deg, ${colors.primary}, ${colors.primaryLight});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-weight: bold;
`

const TitleContainer = styled.div`
    background: ${colors.greyDark};
    padding: 10px;
    margin: 0px;
    border-radius: 5px;
`

const SubHeading = styled.p`
    font-size: ${({width})=> width * 0.03}px;
    font-weight: bold;
    margin-bottom: 0px;
`

const Description = styled.p`
color: ${colors.grey};
font-size: 18px;

`

const PriceTagContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px 0px;
`

const PromotionContainer = styled.div`
    display: flex;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    overflow: hidden;
    justify-content: center;
    width: 80%;
    /* @media (max-width: 968px) {
        display: block;
    } */
`

const PromotionBlock = styled.div`
    width: 50%;
    text-align: left;
    padding: 20px;
    @media (max-width: 968px) {
        width: 80%;
    }
`

const PromotionTitle = styled.h1`
    font-size: 36px;
    font-weight: 400;
    width: 80%;
    margin: 0px;
    text-align: left;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GradientPlanButton = styled.button`
    width: 80%;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    border: 0px;
    background:  ${colors.gradientPrimary};
    color: ${colors.white};
    left: 0;
    margin-top: 20px;
    border-radius: 5px;
`

const PlanButton = styled(GradientPlanButton)`
    background:  transparent;
    border: 1px solid grey;

`

const Stars = styled(FaStar)`
    &:hover{
        color: ${colors.primaryLight};
    }
`