import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";
import useWindowDimensions from  "../../constants/size";
import { HiCheck } from "react-icons/hi";

function PromotionDetailPoint({title, description, href, isColorBorder}) {
    const { width, height } = useWindowDimensions();
  return (
    <Container width={width} height={height} id="eccosystem">
            <InnerContainer width={width} >
                <TickBg><HiCheck size={22} style={{paddingTop: "2px"}} /></TickBg>
                <Title >{title}</Title>
            </InnerContainer>
            <Description>{description}</Description>
    </Container>
  );
}

const Container = styled.div`
    padding-top: 20px;
    /* width: ${({width}) => width * 0.5}px; */
    /* display: flex; */

`

const InnerContainer = styled.div`
    display: flex;
    width: ${({width}) => width * 0.22}px;
    align-items: center;

`

const TickBg = styled.div`
    background-color: ${colors.primaryLight};
    /* width: 30px; */
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    padding: 7px;
`

const Title = styled.h3`
    color: ${colors.white};
    margin: 0px;
    /* padding-left: 10px; */
    /* font-weight: 500; */
    text-align: left;
    font-family: GILROY-MEDIUM;

`

const Description = styled.p`
    color: ${colors.grey};
    font-size: 18px;
    margin: 10px 40px;
    width: 70%;
    text-align: left;

`

export default PromotionDetailPoint;
