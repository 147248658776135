import styled from 'styled-components';
import './App.css';
import NavBar from './components/NavBar';
import colors from './constants/colors';
import useWindowDimensions from './constants/size';
import About from './screens/About';
import Clients from './screens/Clients';
import Contact from './screens/Contact';
import Elements from './screens/Elements';
import Footer from './screens/Footer';
import Home from './screens/Home';
import Pricings from './screens/Pricings';
import { IoIosArrowUp } from "react-icons/io";

function App() {
  const { width } = useWindowDimensions();
  return (
    <div className='App'>
      <NavBar/>
      <Home/>
      <LightBlob />
      <About />
      <DarkLightBlob />
      <Elements/>
      {/* <Pricings/> */}
      <Clients/>
      <Contact />
      <Footer/>
      <FloatingButton width={width} href="#" style={{ zIndex: 2}}>
        <IoIosArrowUp />
      </FloatingButton>
    </div>
  );
}

const DarkLightBlob = styled.div`
  position: fixed;
  top: 65%;
  bottom: 35%;
  transform: translate(-60%, -40%);
  z-index: 0;
  width: 300px;
  height: 150px;
  border-radius: 50%;
  opacity: 0.2;
  background-color: ${colors.primaryLight} ;//#0d2129;
  box-shadow:
    0 0 60px 30px ${colors.primaryLight},  /* inner white */
    0 0 300px 60px ${colors.primaryLight}, /* middle magenta */
    0 0 300px 90px ${colors.primaryLight}, /* outer cyan */
    0 0 400px 120px ${colors.primaryLight}, /* outer cyan */
    0 0 500px 180px ${colors.primaryLight}; /* outer cyan */
`

const LightBlob = styled.div`
  position: fixed;
  top: 15%;
  right: 0;
  transform: translate(60%, 40%);
  z-index: 0;
  width: 50px;
  height: 25px;
  opacity: 0.25;
  border-radius: 50%;
  background-color: ${colors.primaryLight};
  box-shadow:
    0 0 60px 30px ${colors.primaryLight},  /* inner white */
    0 0 100px 60px ${colors.primaryLight}, /* middle magenta */
    0 0 180px 90px ${colors.primaryLight}, /* outer cyan */
    0 0 300px 90px ${colors.primaryLight} ,/* outer cyan */
    0 0 500px 120px ${colors.primaryLight}; /* outer cyan */
`

const FloatingButton = styled.a`
  width: 50px;
  height: 50px;
  background-color: ${colors.greyDark};
  text-decoration: none;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 25px;
  position: fixed;
  bottom: 40px;;
  right: ${({width})=> width * 0.03}px;
  &:hover{
    color: ${colors.primaryLight};
  }
`

export default App;
