const colors = {
    primary: "#cf0084",
    primaryy: "#efb967",
    gradientPrimary: "linear-gradient(45deg, #cf0084, #ff00a2)",
    primaryLight: "#ff00a2",
    grey: "#8A8FA7",
    greyDark: "#0f0f11",
    greyLighter: "#1e1e1e",
    white: "#fff",
    blueDark: "#011827",
    blueLight: "#099dff",
    redDark: "#190302",
    redLight: "#fa5445",
    purpleDark: "#160a40",
    purpleLight: "#6649d5",
    blackish: "#0f0f11",
    lightPurp:  "#2b0017",
    lightYellow: "#312200",
    lightRed: "#340000",
    lighterYellow: "#393206",
    lightGreen: "#142507",
    lightBlue: "#011e2c"
}

export default colors;