import React from "react";
import styled from "styled-components";

const ClientCard = ({image, width}) =>{
    return (
        <Container width={width}>
            <Image width={width} alt="client" src={image} />
        </Container>
    )
}

const Container = styled.div`
    border: 0.5px solid grey;
    display: flex;
    width:${({width})=> width * 0.3}px ;
    padding: ${({width})=> width * 0.02}px;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    /* width: auto; */
    width:${({width})=> width * 0.1}px ;
    opacity: 0.6;
    &:hover{
        opacity: 1;
    }
`

export default ClientCard;