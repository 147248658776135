import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import ServiceCard from "../components/elements/ServiceCard";
import { RiPulseFill } from "react-icons/ri"
import { FiCast, FiPlay } from "react-icons/fi"
import { BsMap } from "react-icons/bs"
import One from "./../assets/images/Picture 1.png";
import PromotionDetailPoint from "../components/elements/PromotionDetailPoint";

const Elements = () =>{
    const { width, height } = useWindowDimensions();
    return (
        <div id="#element" style={{position: "relative", zIndex: 1}}>
            <Container width={width} height={height}>
                <HeadContainer>
                    <TitleContainer>
                        <GradientText>What We Can Do For You</GradientText>
                    </TitleContainer>
                    {/* <Description width={width}>Services provide for you.</Description> */}
                </HeadContainer>
                <InnerContainer>
                    <ServiceCard
                        iconContainerColor={colors.lightPurp}
                        // color={colors.blueLight}
                        // icon={RiPulseFill}
                        imgUrl={require("./../assets/images/Picture 1.png")}
                        title={"Graphic Design"}
                        description={"We create eye-catching designs for your brand. From logos to print and social media, we'll make designs that stand out. Let us bring your brand to life with our creative designs."}
                    />
                    
                    <ServiceCard
                        iconContainerColor={colors.lightYellow}
                        // color={colors.redLight}
                        // icon={FiCast}
                        imgUrl={require("./../assets/images/Picture 2.png")}
                        title={"Investor Pitch Deck Design"}
                        description={"Impress investors with a stunning presentation. We'll showcase your strengths and potential to help you secure funding. Let us help take your business to the next level."}
                    />
                    <ServiceCard
                        iconContainerColor={colors.lightRed}
                        // color={colors.purpleLight}
                        imgUrl={require("./../assets/images/Picture 3.png")}
                        // icon={BsMap}
                        title={"Social Media Handling"}
                        description={"Customized strategy, engaging content, and monitoring. Let us handle your social media presence while you run your business. Contact us to learn more."}
                    />
                </InnerContainer>
                <InnerContainer>
                    <ServiceCard
                        iconContainerColor={colors.lighterYellow}
                        // color={colors.primary}
                        imgUrl={require("./../assets/images/Picture 4.png")}
                        // icon={RiPulseFill}
                        title={"Portfolio Website Design"}
                        description={"Showcase your work with a stunning portfolio website design. We'll create a custom design that reflects your brand. Let us help you make a great first impression online."}
                    />
                    <ServiceCard
                        iconContainerColor={colors.lightGreen}
                        // color={colors.redLight}
                        imgUrl={require("./../assets/images/Picture 5.png")}
                        // icon={FiCast}
                        title={"Web Development"}
                        description={"Create a strong online presence with our web development services. We'll design a website that's visually appealing and functional. From responsive design to e-commerce integration."}
                    />
                    <ServiceCard
                        iconContainerColor={colors.lightBlue}
                        // color={colors.purpleLight}
                        imgUrl={require("./../assets/images/Picture 6.png")}
                        // icon={BsMap}
                        title={"App Development"}
                        description={"Our app development services will help you create a user-friendly and visually appealing app that aligns with your business goals. Let us bring your app idea to life."}
                    />
                </InnerContainer>
                <PromotionContainer>
                    <PromotionBlock>
                        <PromotionImage width={width} alt="vid" src={require("../assets/images/idea.png")} />
                        {/* <GradientPlayBg>
                            <PlayIcon/>
                        </GradientPlayBg> */}
                    </PromotionBlock>
                    <PromotionBlock style={{marginLeft: 30, marginTop: 30}}>
                        <PromotionTitle>DESIGN WITH STYLE,</PromotionTitle>
                        <GradientPromotionTitle width={width}>THAT'S ALL YOU WISH FOR!</GradientPromotionTitle>
                        <Detail>
                            At Xoom Graphics, we believe in the power of great design to transform businesses and bring ideas to life. Our team of experienced designers and developers is passionate about creating innovative, compelling solutions for our clients. We take pride in our attention to detail and our commitment to delivering exceptional results.
                        </Detail>
                        <Title>Our Approach:</Title>
                        <Detail>
                            At our core, we're adventure-seekers, creatives, and makers. We believe in work/life balance and staying true to our value
                        </Detail>
                        <GridDisplay>
                            <PromotionDetailPoint
                                title={"Transparency is key"}
                                // description={"A good traveler has no fixed plansA good traveler has no fixed plans"}
                            />

                            <PromotionDetailPoint
                                title={"Communication is king"}
                                // description={"A good traveler has no fixed plansA good traveler has no fixed plans"}
                            />
                            
                        </GridDisplay>
                        <GridDisplay>
                            <Wrapper>
                            <PromotionDetailPoint
                                title={"Integrity above all"}
                            />
                            </Wrapper>
                            <Wrapper>
                            <PromotionDetailPoint
                                title={"Dare to be different, stay curious"}
                            />
                            </Wrapper>
                        </GridDisplay>
                        <GridDisplay>
                            <PromotionDetailPoint
                                title={"Join the journey"}
                            />
                            
                        </GridDisplay>
                        <div>
                            <GradientAboutButton>About Xoom Graphics</GradientAboutButton>
                        </div>
                    </PromotionBlock>
                </PromotionContainer>
            </Container>
        </div>
    )
}

export default Elements;

const Container = styled.div`
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    /* height: ${({height})=> height * 0.95}px; */
    margin-bottom: 100px;
`

const HeadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
`

const GradientText = styled.p`
    font-size: 20px;
    background: -webkit-linear-gradient(45deg, ${colors.primary}, ${colors.primaryLight});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-weight: bold;
    font-family: GILROY-MEDIUM;
    
`

const TitleContainer = styled.div`
    background: ${colors.greyDark};
    padding: 10px;
    margin: 0px;
    border-radius: 5px;
`

const Description = styled.p`
    font-size: ${({width})=> width * 0.03}px;
    font-weight: bold;
    margin-top: 10px;    
    font-family: GILROY-MEDIUM;

`

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`

const PromotionContainer = styled.div`
    display: flex;
    margin-top: 100px;
    @media (max-width: 968px) {
        display: block;
    }
`

const PromotionBlock = styled.div`
    width: 50%;
    position: relative;
    @media (max-width: 968px) {
        width: 80%;
    }
`

const PromotionImage = styled.img`
    width: 100%;
    border-radius: 10px;
`

const GradientPlayBg = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: ${colors.gradientPrimary};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    left: 0; 
    right: 0; 
    margin: auto auto auto auto;
`

const PlayIcon = styled(FiPlay)`
     font-size: 35px;
`

const PromotionTitle = styled.h1`
    font-size: 36px;
    font-weight: 400;
    width: 80%;
    margin: 0px;
    text-align: left;
    font-family: GILROY-MEDIUM;

`
const GradientPromotionTitle = styled(PromotionTitle)`
    font-family: GILROY-heavy;
    background: -webkit-linear-gradient( -90deg, #030000, ${colors.primary});
    color: transparent;
    -webkit-background-clip: text;
    line-height: ${({width})=> width * 0.03  }px;

`

const GradientAboutButton = styled.button`
    position: absolute;
    width: 270px;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    border: 0px;
    background:  ${colors.gradientPrimary};
    color: ${colors.white};
    left: 0;
    margin-top: 20px;
    border-radius: 5px;
`

const Detail = styled.p`
    /* color: ${colors.grey}; */
    padding: 5px 0px 0px 0px ;
    font-family: GILROY-MEDIUM;
    text-align: left;
`

const Title = styled.p`
    text-align: left;
    font-size: 25px;
    text-decoration: none;
    font-family: GILROY-bold;
    margin: 5px 0 0 0;
`

const GridDisplay = styled.div`
    display: flex;
    /* justify-content: space-between;
    /* text-align: left; */
    /* width: 100%;  */
    /* display: grid; */
    /* grid-row-gap:    20px; */
    grid-gap: 0rem 5rem;
    grid-column-gap: 5rem;
    grid-template-columns: repeat(2, 40%)
`

const Wrapper = styled.div`
    /* text-align: left;
    width: 50%; */
`