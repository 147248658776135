import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";

const FooterCard = ({title, description, description1, description2, description3, description4, description5 }) =>{
    return (
        <Container>
            <Title>{title}</Title>
            { description && <Description>{description}</Description>}
            { description1 && <Description>{description1}</Description>}
            { description2 && <Description>{description2}</Description>}
            { description3 && <Description>{description3}</Description>}
            { description4 && <Description>{description4}</Description>}
            { description5 && <Description>{description5}</Description>}
        </Container>
    )
}

const Container = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
`

const Title = styled.h3`
    text-align: left;

`

export const Description = styled.a`
    text-decoration: none;
    color: ${colors.grey};
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-align: left;
    width: fit-content;

    &:hover{
        color: ${colors.primaryLight};
    }

    &:after {
        display:block;
        content: '';
        border-bottom: solid 3px ${colors.primaryLight};  
        transform: scaleX(0);  
        transition: transform 0.5s ease-in-out;
    }   
    &:after{ transform-origin: 100% 50%; }
    &:hover:after{ transform: scaleX(1); transform-origin:   0% 50%; }

`

export default FooterCard;