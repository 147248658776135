import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import "../styles/Home.css"

const About = () =>{
    const { width, height } = useWindowDimensions();
    return (
        <div id="#about" style={{position: "relative",zIndex: 1}}>
            <Container width={width} height={height}>
                <MainImage width={width} height={height} src={require("../assets/images/bg-image.jpg")} alt="bg Image" />
                <InnerContainer>
                    <NumberContainer width={width}>
                        <NumberTextContainer>
                            <NumberText className="clientNumberAnimate" ></NumberText>
                            <NumberText>+</NumberText>
                        </NumberTextContainer>
                        <ParaText>Happy Clients</ParaText>
                    </NumberContainer>
                    <NumberContainer width={width}>
                        <NumberTextContainer>
                            <NumberText className="programsNumberAnimate" ></NumberText>
                            <NumberText>+</NumberText>
                        </NumberTextContainer>
                        <ParaText>Years Experience</ParaText>
                    </NumberContainer>

                    <NumberContainer width={width}>
                        <NumberTextContainer>
                            <NumberText className="projectsNumberAnimate" ></NumberText>
                            <NumberText>k+</NumberText>
                        </NumberTextContainer>
                        <ParaText>Successful Projects</ParaText>
                    </NumberContainer>
                    <NumberContainer width={width}>
                        <NumberTextContainer>
                            <NumberText className="employeeNumberAnimate" ></NumberText>
                            <NumberText>+</NumberText>
                        </NumberTextContainer>
                        <ParaText>Employees</ParaText>
                    </NumberContainer>
                </InnerContainer>
            </Container>
        </div>
    )
}

export default About;

const Container = styled.div`
    margin-left: ${({width})=> width * 0.05}px;
    margin-right: ${({width})=> width * 0.05}px;
    border-bottom: 1px solid gray;
    margin-bottom: ${({width})=> width * 0.1}px;
`


const MainImage = styled.img`
    width: ${({width})=> width * 0.9}px;
    height: ${({height})=> height}px;
    border-radius: 20px;
`

const InnerContainer = styled.div`
    display: flex;
    
`

const NumberContainer = styled.div`
    width: ${({width})=> width * 0.3}px;
    background-color: "red";
    align-items: center;
    margin: ${({width})=> width * 0.07}px 0px;
`

const NumberTextContainer = styled.p`
    display: flex;
    justify-content: center;
    font-family: GILROY-bold;
`

const NumberText = styled.p`
    /* color: ${colors.primaryLight}; */
    font-size: 55px;
    margin: 0px;
    font-weight: bold;
    background: -webkit-linear-gradient( -90deg, #180808, ${colors.primary}); 
    color: transparent;
    -webkit-background-clip: text;
    
`

const ParaText = styled.p`
    font-size: 25px;
    text-align: center;
    margin-top: 15px;
    font-family: GILROY-Medium;

    
`