import React from "react";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import styled from "styled-components";
import FooterCard, { Description } from "../components/footer/FooterCard";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import { FaPinterestP } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Footer = () =>{
    const { width } = useWindowDimensions();

    return (
        <Container >
            <InnerContainer width={width}>
                <FooterCard
                    title={"Services"}
                    description={"About"}
                    description1={"Portfolio"}
                    description2={"Contact"}
                    description3={"Service"}
                />
                <FooterCard
                    title={"Solutions"}
                    description={"Pricing"}
                    description1={"Team"}
                    description2={"Advance Tab"}
                    description3={"Service"}
                />
                <FooterCard
                    title={"Company"}
                    description={"Pricing"}
                    description1={"Team"}
                    description2={"Advance Tab"}
                    description3={"Service"}
                />
                <FooterCard
                    title={"Resources"}
                    description={"Pricing"}
                    description1={"Team"}
                    description2={"Advance Tab"}
                    description3={"Service"}
                />
                <div>
                    <Title>Stay With Us.</Title>
                    <Message>2000+ Our clients are subscribe</Message>
                    <Message>Around the World</Message>
                    <Title>Lahore, Pakistan.</Title>
                    <RowContainer>
                        <SocialButton href="https://web.facebook.com/Xoomgraphicofficial">
                            <FiFacebook />
                        </SocialButton>
                        <SocialButton href="https://www.pinterest.com/Xoomgraphicofficial/">
                            {/* <FiTwitter /> */}
                            <FaPinterestP/>
                        </SocialButton>
                        <SocialButton href="https://bit.ly/3m7usvX">
                            <FiInstagram />
                        </SocialButton>
                        <SocialButton href="https://www.linkedin.com/company/xoom-graphic-5b3884264/">
                            <FiLinkedin />
                        </SocialButton>
                        <SocialButton href="mailto:Xoomgraphic2022@gmail.com">
                            <IoMdMail />
                        </SocialButton>
                    </RowContainer>
                    
                </div>
                
            </InnerContainer>
            <BottomNav width={width}>
                <RowContainer>
                    <BottomNavLinks style={{paddingLeft: 0}}>Privacy Policy</BottomNavLinks>
                    <BottomNavLinks>Terms and Conditions</BottomNavLinks>
                    <BottomNavLinks style={{border: 0}}>Contact Us</BottomNavLinks>
                </RowContainer>
                <Message>© 2023 XoomGraphics.</Message>
            </BottomNav>
        </Container>
    )
}

const Container = styled.div`
    background-color: ${colors.greyLighter};
`

const InnerContainer = styled.div`
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    display: flex;
    padding-top: 40px;
    margin-bottom: 100px;
`

const Title = styled.h3`
    text-align: left;

`

const Message = styled.p`
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-align: left;
    color: ${colors.grey};
    font-family: GILROY-Medium;
`

const BottomNav = styled.div`
    border-top: 0.5px solid ${colors.grey};
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    justify-content: space-between;
`

const RowContainer = styled.div`
    display: flex;
`

const BottomNavLinks = styled(Description)`
    border-right: 0.3px solid ${colors.greyLighter};
    padding: 0px 10px;
    margin: 0px;
    /* line-height: 20px;; */
`

const SocialButton = styled.a`
  width: 50px;
  height: 50px;
  background-color: ${colors.greyDark};
  border: 0px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
  border-radius: 50%;
  font-size: 25px;
  
  &:hover{
    background-color: ${colors.primaryLight};
  }
`

export default Footer;