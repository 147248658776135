import React, { useState } from "react";
// import { HashLink } from "react-router-hash-link";
import styled, {css} from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import {GiHamburgerMenu} from "react-icons/gi"
import "../styles/Navbar.css"

function NavBar() {
    const { width } = useWindowDimensions();
    const [ isMenueOpen, setIsMenuOpen ] = useState(false);

  return (
    <NavContainer width={width} className="navbar" style={{position: "relative", zIndex: 1}}>
        
        <LogoBox>
            <Logo alt="Gear" style={{ marginTop: 6, marginLeft: 10}} src={require("../assets/images/logo.png")} />
            {/* <LogoText>XOOM GRAPHICS</LogoText> */}
        </LogoBox>
        <CreateAccountBtn 
            style={{marginTop: 20}}
        >
            <a style={{textDecoration: "none", color: "white", padding: 15}} href="mailto:Xoomgraphic2022@gmail.com">
                Contact Us
            </a>
        </CreateAccountBtn>
        {/* {
        width <= 768 ?(
        <div style={{float: "left"}}>
            <button style={{backgroundColor: "transparent", width: 45, alignItems: "center", border: 0, padding: isMenueOpen ? 7 : 0, color: "white", fontSize: 28}} 
                onClick={()=>{setIsMenuOpen(!isMenueOpen)}}
            >
                {isMenueOpen ? "X"  :<GiHamburgerMenu style={{paddingTop: 10}} />}
            </button> 
            { isMenueOpen &&(
            <InnerContainer >
                <HashRouterLink smooth to={"#"} >Home</HashRouterLink>
                <HashRouterLink smooth to={"#about"}>About</HashRouterLink>
                <HashRouterLink smooth to={"#elements"}>Elements</HashRouterLink>
                <HashRouterLink smooth to={"#blog"}>Blog</HashRouterLink>
                <HashRouterLink smooth to={"#portfolio"}>Portfolio</HashRouterLink>
                <HashRouterLink smooth to={"#contact"}>Contact</HashRouterLink>
                <CreateAccountBtn 
                    style={{marginTop: 20}}
                >
                    Contact Us
                </CreateAccountBtn>
            </InnerContainer>
            )
            }
        </div>
            )
        :
        <NavInnerContainer>
            <HashRouterLink smooth to={"#"} >Home</HashRouterLink>
            <HashRouterLink smooth to={"#about"}>About</HashRouterLink>
            <HashRouterLink smooth to={"#elements"}>Elements</HashRouterLink>
            <HashRouterLink smooth to={"#blog"}>Blog</HashRouterLink>
            <HashRouterLink smooth to={"#portfolio"}>Portfolio</HashRouterLink>
            <HashRouterLink smooth to={"#contact"}>Contact</HashRouterLink>

            <CreateAccountBtn >
                Contact Us
            </CreateAccountBtn>
        </NavInnerContainer>
        } */}
    </NavContainer>
  );
}

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    background-color: black;
    justify-content: space-between;
    /* border-bottom: 0.5px solid ${colors.grey}; */
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: 1200px) {
        padding-left: ${({width})=> width * 0.05}px;
        padding-right: ${({width})=> width * 0.05}px;
    }

    @media (max-width: 1199px) {
        padding-left: ${({width})=> width * 0.05}px;
        padding-right: ${({width})=> width * 0.05}px;
        
    }

`

const LogoBox = styled.div`
    display: flex;
`

const LogoText = styled.h1`
    margin: 0px;
    margin-top: 10px;
    margin-left: 5px;
`

const Logo = styled.img`
    width: 120px;
    height: 70px;
`

const NavInnerContainer = styled.div`
    display: flex;
    align-items: center;

    /* @media (max-width: 768px) {
        display: block;
    } */
`

const InnerContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    width: 250px;
    padding: 20px;
    background-color: black;
    text-align: left;
    border-radius: 10px;

    @media (max-width: 768px) {
        
    }
`

const linkStyle = css`
    color: ${colors.white};
    text-decoration: none;
    opacity: 1;
    @media (min-width: 1200px) {
        padding-right: 30px;
    }

    @media (max-width: 1199px) {
        padding-right: 15px;
    }

    @media (max-width: 768px) {
        /* padding-right: 10px; */
        /* text-align: center; */
        padding: 10px;
        border-bottom: 0.5px solid ${colors.socialGreyDark};
        padding-left: 0px;
    }
`

const HashRouterLink = styled.a`
    ${linkStyle};
`

const CreateAccountBtn = styled.button`
    border: 0px;
    background:  ${colors.gradientPrimary};
    color: ${colors.white};
    border-radius: 5px;
    cursor: pointer;

    @media (min-width: 800px) {
        padding: 10px 20px;
    }

    @media (max-width: 799px) {
        padding: 10px 10px;
        font-size: 12px;
    }
`

export default NavBar;