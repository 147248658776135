import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";

const Home = () =>{
    const { width, height } = useWindowDimensions();
    return (
        <div id="#">
            <Container width={width} height={height}>

                <GradientText>WELCOME TO XOOM GRAPHICS!</GradientText>
                <MainHeading width={width}>WHERE CREATIVITY</MainHeading>
                {/* <ShadowWra pper width={width}> .</ShadowWrapper> */}
                <div ></div>
                <ShadowHeading width={width}>MEETS INNOVATION</ShadowHeading>
                <MainDescription width={width}>
                    Our team of skilled designers & developers is passionate about delivering innovative, high-quality solutions to businesses of all sizes. 
                </MainDescription>
                <MainButtonContainer>
                    <MainButton>
                        Welcome
                    </MainButton>
        
                    <SecondButton style={{background: "transparent", border: "1px solid gray"  }}>
                        Contact Us
                    </SecondButton>
                </MainButtonContainer>
                
            </Container>
        </div>
    )
}

export default Home;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${({width})=> width * 0.05}px;
    padding-right: ${({width})=> width * 0.05}px;
    height: ${({height})=> height * 0.91}px;
    align-items: center;
    justify-content: center;
`

const GradientText = styled.p`
    font-size: 20px;
    background: -webkit-linear-gradient(45deg, ${colors.primary}, ${colors.primaryLight});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: ${({width})=> width * 0.1 }px;
    font-family: GILROY-MEDIUM;
`

const MainHeading = styled.h1`
    width: ${({width})=> width}px;
    font-size: ${({width})=> width / 26}px;
    letter-spacing: 4px;
    margin: 0px;
    z-index: 0;
    /* padding-bottom: 0px; */
    font-family: GILROY-Bold;
`


const ShadowHeading = styled.h1`
    width: ${({width})=> width }px;
    font-size: ${({width})=> width / 18}px;
    margin: 0px;
    letter-spacing: 4px;
    font-family: GILROY-heavy;
    background: -webkit-linear-gradient( -90deg, #030000, ${colors.primary});
    color: transparent;
    -webkit-background-clip: text;
    line-height: ${({width})=> width * 0.06  }px;

`

const MainDescription = styled.p`
    width: ${({width})=> width * 0.65}px;
    font-size: ${({width})=> width / 60}px;
    font-family: GILROY-MEDIUM;

`

const MainButtonContainer = styled.div`
    display: flex;
`

const MainButton = styled.button`
    width: 120px;
    height: 50px;
    background: ${colors.gradientPrimary};
    color: white;
    border: 0px;
    border-radius: 25px;
`

const SecondButton = styled(MainButton)`
    &&& {
        margin-left: 10px;
        background: "transparent"
    }
    
    
`

